import { IMAGE_BUCKET_URL, IMGBUCKETURL } from "../../DataStore/ApiConstant";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import { useRef } from "react";

const FeaturedGames = ({
  login,
  openPopup,
  siteImages,
  cashier = "false",
  setSelectedGameType,
  openGeneralModal,
}) => {
  const history = useHistory();
  const gameWrapperArea = useRef(null);

  const featuredGameClicked = (type) => {
    if(cashier === "true"){
      history.push("/lobby")
      gameWrapperArea.current?.scrollIntoView({ behavior: "smooth" });
      setSelectedGameType(type)
    }else{
      gameWrapperArea.current?.scrollIntoView({ behavior: "smooth" });
      setSelectedGameType(type)
    }
    // cashier === "true" ? history.push("/lobby") : setSelectedGameType(type);
  };

  return (
    <div className="container-fluid mt-lg-3">
    </div>
  );
};


const setStatetoProps = (state) => {
  return {
    LanguageContent: state.SiteLanguages,
    siteImages : state.siteImages
  };
};

export default connect(setStatetoProps)(FeaturedGames);

// export default FeaturedGames;
