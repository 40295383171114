import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import {
  BASE_URL,
  FUND_TRANSFER_TYPE_URL,
  SPORT_LOGIN_URL,
  POINTS_URL,
  IMAGE_BUCKET_URL
} from "../../DataStore/ApiConstant";
import { connect } from "react-redux";
import _ from "lodash";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import { usePointsDetails } from "../../services/cashier";
import { usePromotions } from "../../services/promotions";
import { promotion } from "../../DataStore/atom";
import { useAtom } from "jotai";

//   useEffect(() => {

//   }, []);
const Points = ({setSelectedGameType}) => {


const history = useHistory();
const pointsDetails = usePointsDetails()
const upcomingPromotion = usePromotions()
const [promo, setPromo] = useAtom(promotion);
let points = 0 
let upcomigPromo = !upcomingPromotion.isLoading && upcomingPromotion?.data?.filter((item) => item.upcoming_promotion == 1)
// console.log(!upcomingPromotion.isLoading && upcomingPromotion.data);
const today = new Date().toISOString().split('T')[0]; 
const [fromDate, setFromDate] = useState("");
const [toDate, setToDate] = useState("");
const [err, setErr] = useState("");
const [pointsAvailable, setPointsAvailable] = useState("");


const gameWrapperArea = useRef(null);

const setPromtion = (type, promotions) => {
  // if(cashier === "true"){
  //   gameWrapperArea.current?.scrollIntoView({ behavior: "smooth" });
  //   setSelectedGameType(type)
  // }else{
      history.push("/lobby")
      console.log("gg", promotions);
      gameWrapperArea.current?.scrollIntoView({ behavior: "smooth" });
      setSelectedGameType(type)
      setPromo(promotions)
      
      // }
}

const handleDateChange = (e) => {
  const inputValue = e.target.value;
  setFromDate(inputValue);
};
const handleToDateChange = (e) => {
  const inputValue = e.target.value;
  setToDate(inputValue);

};
const submitPoints = (e) => {
  e.preventDefault();
  if(fromDate == '' || toDate ==''){
    setErr("Please enter a valid date range")
    setTimeout(() => {
      setErr("")
    }, 4000);
    return
  }
  axios
  .get(POINTS_URL + `?startdate=${fromDate}&enddate=${toDate}`)
  .then((resp) => {
    console.log(resp?.data?.points);
    if(resp?.data?.points > 0){
      points = Math.floor(resp?.data?.points)
    }
    setPointsAvailable("You Have " + points + " Points")
  })
  .catch((err) => {});
}
    return (
      <>
        <div className="row p-4">
          <div className="col-12 col-xl-6 col-lg-6 col-md-8 col-sm-8 offset-xl-3 offset-lg-3 offset-md-2 offset-sm-2 offset-0">
            <div className="row mb-3">
              <div className="col-12">
                <div className="cashierNewTitel text-center">Points</div>
                <div
                  className="cashierNewTitel text-center"
                  style={{ color: "#25ac5b" }}
                >
                  {!pointsDetails.isLoading && pointsDetails.data?.point?.points}
                </div>
              </div>

              {/* <div className="col-6">
                <div className="cashierNewTitel text-center">Rank</div>
                <div
                  className="cashierNewTitel text-center"
                  style={{ color: "#25ac5b" }}
                >
                {!pointsDetails.isLoading && pointsDetails.data?.point?.rank}
                </div>
              </div> */}
            </div>
            
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 col-sm-6  justify-content-center align-self-center">
                <div className="cashierNewTitel text-center">YOUR POINTS</div>
                <div
                  className="cashierNewTitel text-center"
                  style={{ color: "#25ac5b" }}
                />
                <div className="row">
                  <div className="col">
                    <label htmlFor="formGroupExampleInput" className="form-label">
                      From
                    </label>
                    <input onChange={(e) => handleDateChange(e)} type="date" className="form-control" name="from" min="1900-01-01" />
                  </div>
                  <div className="col">
                    <label htmlFor="formGroupExampleInput" className="form-label">
                      To
                    </label>
                    <input onChange={(e) => handleToDateChange(e)} type="date" className="form-control" name="to" min="1900-01-01"/>
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col-12">
                    <div className="row">
                      <div className="col-12">
                        <div
                          className="cashierNewTitel text-center my-2"
                          style={{ color: "#25ac5b", width: "100%" }}
                        >
                          {pointsAvailable}
                        </div>
                        <div className="form-message m-2" style={{ color: "red" }}>
                          {err}
                        </div>
                      </div>
                      <div className="col-6 col-lg-6 col-md-6 col-sm-6 pt-2">
                        <a href="#">
                          <div onClick={(e) => submitPoints(e)} className="newcashierButton"> SUBMIT</div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
              <div className="col-12 col-md-6 col-lg-6 col-sm-6  justify-content-center">
                <div className="cashierNewTitel text-center mb-2">
                  UPCOMING PROMOTIONS
                </div>
                <div className="row">
                  <div className="col-12">
                    <a href="#" onClick={(e) => {e.preventDefault(); setPromtion("promotions", upcomigPromo[0])}}>
                      <div className="card">
                        <img
                          src={IMAGE_BUCKET_URL + upcomigPromo[0]?.img}
                          className="card-img-top"
                          alt="..."
                        />
                        <div className="card-body text-center">
                          <h5 className="card-title">{upcomigPromo[0]?.heading}</h5>
                          <p className="card-text">
                            {upcomigPromo[0]?.subheading}
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
  const BindStateToProps = (state=>{
    return {
        siteImages : state.siteImages
    }
  })
  export default connect(BindStateToProps)(Points);
// export default connect()(Points);