import { useQuery } from "@tanstack/react-query";
import APIClient from "../providers/apiClient";
import ms from "ms";

export const useGameBoardResults = (game) => {
  const apiClient = new APIClient("/baccarat/get-score-board-all/"+game);
  return useQuery({
    queryKey: [game],
    queryFn: apiClient.getAll,
    // staleTime: ms("5s"),
  });
};

export const useRouletteHistory = () => {
  const apiClient = new APIClient("/roulette/result-list");
  return useQuery({
    queryKey: ["gameRouletteHistory"],
    queryFn: apiClient.getAll,
    // staleTime: ms("5s"),
  });
};

export const useGetForU = () => {
  const apiClient = new APIClient("/getforyou");
  return useQuery({
    queryKey: ["getforyou"],
    queryFn: apiClient.getAll,
    // staleTime: ms("5s"),
  });
};
