import { connect } from "react-redux";
import { IMAGE_BUCKET_URL } from "../../DataStore/ApiConstant";
import Carousel from "react-bootstrap/Carousel";
import TranslatesContent from "../../DataStore/Languages/translatesContent";

const MainBanner = ({ bannerImages, login, openPopup }) => {
  return (
    <Carousel>
      {/* use the following code after the api is updated */}

      {bannerImages &&
        bannerImages.map((bannerImg, key) => {
          let imgSrc = `${IMAGE_BUCKET_URL}${bannerImg}`;

          return (
            <Carousel.Item className="p-0">
              <a>
                <img src={imgSrc} className="d-block w-100" alt="..." />
                {!login && (
                  <div class="position-absolute top-50 start-50 translate-middle sliderButtons text-center ">
                    <a
                      class="align-middle hoverButton "
                      onClick={(e) => {
                        e.preventDefault();
                        openPopup("login");
                      }}
                    >
                      <TranslatesContent contentKey="play-now" />
                    </a>
                  </div>
                )}
              </a>
            </Carousel.Item>
          );
        })}
    </Carousel>
  );
};

const mapStateToProps = (state) => {
  return {
    bannerImages: state.siteImages.images.mainslider,
  };
};
export default connect(mapStateToProps)(MainBanner);
