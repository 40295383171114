import { useEffect, useState } from "react";
import { useRouletteHistory } from "../../../services/gameBoards";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Boards = ({ config, isLoad }) => {
  const [gameResult, setGameResult] = useState([]);
  const [gameConfig, setGameConfig] = useState([]);
  const { data, isLoading } = useRouletteHistory();

  const loadGame = (gameLink) => {
    window.location.replace("/liveroulette/#rou" + gameLink);
  };

  useEffect(() => {
    if (!isLoad && config) {
      const configData = Object.values(config);
      if (
        configData[1] && 
        configData[1]["rou"] &&  
        configData[1]["rou"][1]
      ) {
        configData[1]["rou"][1].shift(); 
        setGameConfig(configData[1]["rou"][1]);
      } else {
        setGameConfig([]); 
      }
    }
  }, [isLoad, config]);

  useEffect(() => {
    if (!isLoading && data) {
      setGameResult(data);
    }
  }, [isLoading, data]);

  const tables = [1, 2, 3, 4, 5, 6, 7, 8];

  return (
    <div className="row">
      {tables.map((tbl) => {
        const isClosed = gameConfig[tbl - 1] && gameConfig[tbl - 1][4] === 0;

        return (
          <div className="col-lg-4 mb-4" style={{ position: "relative", cursor:"pointer" }} key={tbl} onClick={!isClosed ? () => loadGame(tbl) : null}>
            {isClosed ? (
              <div className="position-absolute top-50 start-50 translate-middle d-flex flex-column justify-content-center align-items-center tableClose tableCloseText">
                <div className="row">
                  <div className="tableCloseText">Closed</div>
                </div>
              </div>
            ) : (
              <div className="content-overlay text-center pt-5">
                {/* {gameConfig[tbl - 1] ? JSON.stringify(gameConfig[tbl - 1]) : `Table ${tbl} config missing`} */}
                <button className="hoverButton" onClick={() => loadGame(tbl)}>
                  PLAY
                </button>
              </div>
            )}
            <div className="row border rounded border-white p-1">
              <div className="row m-0 bg-white">
                <div className="col-12 text-center my-3">
                  <div className="d-flex justify-content-center">
                    {data && data[tbl] && data[tbl][1][0] ? (
                      data[tbl][1][0].map((item, i) => (
                        <div
                          key={i}
                          id={`result-d-${i}`}
                          className={`flex-fill ${i === 0 ? "newRouletteResultBoxRed active" : ""}`}
                          style={{
                            border: i === 0 ? "1px solid #fff" : "",
                            color: i === 0 ? "#fff" : item[1],
                            backgroundColor: i === 0 ? item[1] : "",
                          }}
                        >
                          {item[0]}
                        </div>
                      ))
                    ) : (
                      "N/A"
                    )}
                  </div>
                </div>
                <div className="col-12 text-center mb-3">
                  <div className="d-flex justify-content-center">
                    <div className="flex-fill">
                      <div className="newRouletteResText text-black">RED</div>
                      <div id="newRouletteResred" className="newRouletteResRed">
                        {data && data[tbl] && data[tbl][1][1] && data[tbl][1][1].statictics
                          ? data[tbl][1][1].statictics.red + "%"
                          : "N/A"}
                      </div>
                    </div>
                    <div className="flex-fill">
                      <div className="newRouletteResText text-black">BLACK</div>
                      <div id="newRouletteResblack" className="newRouletteResBlack">
                        {data && data[tbl] && data[tbl][1][1] && data[tbl][1][1].statictics
                          ? data[tbl][1][1].statictics.black + "%"
                          : "N/A"}
                      </div>
                    </div>
                    <div className="flex-fill">
                      <div className="newRouletteResText text-black">1-18</div>
                      <div id="newRouletteRes1-18" className="newRouletteRes118">
                        {data && data[tbl] && data[tbl][1][1] && data[tbl][1][1].statictics
                          ? data[tbl][1][1].statictics["1-18"] + "%"
                          : "N/A"}
                      </div>
                    </div>
                    <div className="flex-fill">
                      <div className="newRouletteResText text-black">19-36</div>
                      <div id="newRouletteRes19-36" className="newRouletteRes1936">
                        {data && data[tbl] && data[tbl][1][1] && data[tbl][1][1].statictics
                          ? data[tbl][1][1].statictics["19-36"] + "%"
                          : "N/A"}
                      </div>
                    </div>
                    <div className="flex-fill">
                      <div className="newRouletteResText text-black">ODD</div>
                      <div id="newRouletteResodd" className="newRouletteResOdd">
                        {data && data[tbl] && data[tbl][1][1] && data[tbl][1][1].statictics
                          ? data[tbl][1][1].statictics.odd + "%"
                          : "N/A"}
                      </div>
                    </div>
                    <div className="flex-fill">
                      <div className="newRouletteResText text-black">EVEN</div>
                      <div id="newRouletteReseven" className="newRouletteResEven">
                        {data && data[tbl] && data[tbl][1][1] && data[tbl][1][1].statictics
                          ? data[tbl][1][1].statictics.even + "%"
                          : "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-6 tableCountBoardName">{data && data[tbl] ? data[tbl][0] : `ROU-${tbl}`}</div>
                  <div className="col-6">
                    <div className="tableCountBoardLimit text-end">
                      {gameConfig[tbl - 1] ? gameConfig[tbl - 1][2] : "N/A"} - {gameConfig[tbl - 1] ? gameConfig[tbl - 1][3] : "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Boards;
