import { useEffect, useRef, useState } from "react";
import { useRouletteHistory } from "../../../services/gameBoards";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Boards from "./Boards.js";


const RouletteBoard = ({config, isLoad}) => {

  const { data, isLoading, status} = useRouletteHistory();
  const [rouletteData, setRouletteData] = useState([]);

  useEffect(() => {
    if (!isLoading && data && data[1].length > 0) {
      setRouletteData(data[1]);
    } else {
      setRouletteData([]);
    }
  }, [isLoading, data]);
  

  return (
    <>
    {/* {(  rouletteData.length > 0) &&  rouletteData?.map((data, key) => ( */}
      <Boards config={config} isLoad={isLoad}/>
    {/* } */}

  </>
  );
    
};

export default RouletteBoard;
