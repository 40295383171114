// import { GUEST_GAMES_DATA } from "../../DataStore/ApiConstant";
import { useEffect,useState } from "react";
import LoyaltyContent from "../Loyalty/LoyalityContent";

const LoyaltyArea = ({ openPopup, login }) => {
  
  useEffect(() => {}, []);
  return (
    <>
      <div className="container-fluid mt-lg-5" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
        <LoyaltyContent openPopup={openPopup} login={login} />
      </div>
      
    </>
  );
};

export default LoyaltyArea;
