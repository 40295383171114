import { useState, useEffect } from "react";
import { validateNumberInput } from "../../utils";
import {
  CRYPTOURL,
  DEOSITBONUSURL,
  DEPOSITURL,
} from "../../DataStore/ApiConstant";
import axios from "axios";
import { BonusCalc } from "./BonusCalc";
import { CalculateTurnover } from "./turnOverCalc";
// import WhatsappButton from "../Cashier/whatsappButton";
import BonusOffer from "../TransactionCommon/BonusOffer";
import { TOAST_CONFIG } from "../../constants";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import _ from "lodash";
import { getFormData, calculateBonus, calculateTurnover } from "../../utils";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import { useWithdrawBonus } from "../../services/cashier";

import { useAtom } from "jotai";
import { bonusStore } from "../../DataStore/atom";

let validator = {
  // amount: yup
  //   .number()
  //   .typeError("Amount must be a number")
  //   .required("Please provide amount.")
  //   .min(1, "Must be greater than 0"),
};
const UpiPayment = () => {
  const [BonusStore, setBonusStore] = useAtom(bonusStore);

  const bonus = useWithdrawBonus();

  let [amount, setAmount] = useState(null);
  const [depositBonus, setDepositBonus] = useState(null);
  const [paymentErr, setPaymentErr] = useState(false);
  const [rdo, setRdo] = useState("upi");
  const [load, setLoad] = useState(false);
  const [reBonus, setReBonus] = useState(0);
  const [reTurn, setReTurn] = useState(0);
  const schema = yup.object().shape(validator);
  const [selectedCurrency, setSelectedCurrency] = useState('USD');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  let submitHandler = async (upiObj) => {
    let url = `https://ballysbet.com/api/ipg/online-payment/visa-master-checkout?currency=${selectedCurrency}&amount=${amount}`
    window.location.href=url;
    console.log(amount);
    // setLoad(true);
    // let bonusAmt = 2;
    // let url = window.location.pathname;
    // depositBonus == null ? (bonusAmt = 2) : (bonusAmt = 1);
    // let conArr = { ...upiObj, url, bonus: bonusAmt, amount: amount };
    // axios
    //   .post(rdo == "upi" ? DEPOSITURL : CRYPTOURL, getFormData(conArr))
    //   .then((resp) => {
    //     if (resp?.data?.state == false) {
    //       setPaymentErr(resp.data.msg);
    //       // toast.error(resp?.data?.msg, TOAST_CONFIG);
    //       setLoad(false);
    //     }

    //     if (rdo == "upi") {
    //       resp.status && resp.data.data
    //         ? (window.location.href = resp.data.data)
    //         : setPaymentErr(resp.data.msg);
    //       setLoad(false);
    //     } else {
    //       resp.status && resp.data.data
    //         ? (window.location.href = `https://merchant.net-cents.com/widget/payment/currencies?data=${resp.data.data.token}`)
    //         : setPaymentErr(resp.data.msg);
    //       // toast.error(paymentErr, TOAST_CONFIG);
    //       setLoad(false);
    //     }
    //   })
    //   .catch((err) => {
    //     setPaymentErr(err);
    //     // toast.error("err", TOAST_CONFIG);
    //     setLoad(false);
    //   });
  };

  const bonusOfferSwitch = async (status) => {
    // alert(status)
    setBonusStore(status);
    if (status) {
      axios
        .get(DEOSITBONUSURL)
        .then((resp) => {
          setDepositBonus(resp);
        })
        .catch((err) => {});
    } else {
      setDepositBonus(null);
    }
  };


  const calculateBonuses = (reAmount) => {
    setAmount(reAmount);
    if (reAmount != "" && (reAmount < 500 || reAmount > 100000)) {
      setPaymentErr("Please enter amount between min and max limit");
    } else {
      setPaymentErr(false);
    }

    if (bonus.data) {
      let bonusRet = calculateBonus(
        reAmount,
        bonus?.data[2]["maxbonus"] || 0,
        bonus?.data[2]["bonusprecent"] || 0
      );
      setReBonus(bonusRet);
      let turnRet = calculateTurnover(
        reAmount,
        bonus?.data[2]["maxbonus"] || 0,
        bonus?.data[2]["turnover"] || 0,
        bonus?.data[2]["bonusprecent"] || 0
      );
      setReTurn(turnRet);
    }
  };

  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e.target.value);
  };

  
  const handleFocus = (event) => {
    event.target.select();
  };
  useEffect(() => {
    bonusOfferSwitch(BonusStore);
  }, [BonusStore]);
  return (
    <div
      class="tab-pane fade show active "
      id="v-pills-home"
      role="tabpanel"
      aria-labelledby="v-pills-home-tab"
    >
      <div class="row">
        <form onSubmit={handleSubmit(submitHandler)}>
          <div class="col-12 col-lg-10 col-md-8 col-sm-10 col-xl-10 offset-xl-1 offset-lg-1 offset-md-2 offset-sm-2 offset-0">
            <div class="row">
              <div class="col-12 col-lg-6 col-md-6 col-sm-12">
                <div class="row">
                  <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 offset-xl-2 offset-lg-2 offset-md-2 offset-sm-0 offset-0">
                    <div class="row">
                      {/* <label
                        for="exampleFormControlInput1"
                        class="cashierNewTitel"
                      >
                        <TranslatesContent contentKey="select-gateway" />
                      </label> */}
                      {/* <div class="col-12 col-lg-4 col-md-4 col-sm-6">
                        <div class="radio">
                          <input
                            id="radio-1"
                            value={"upi"}
                            onClick={(e) => setRdo("upi")}
                            name="radio"
                            type="radio"
                            checked={rdo == "upi" ? true : false}
                          />
                          <label for="radio-1" class="radio-label">
                            <TranslatesContent contentKey="gateway-a" />
                          </label>
                        </div>
                      </div> */}
                      {/* <div class="col-12 col-lg-4 col-md-4 col-sm-6">
                        <div
                          class="radio"
                          style={{
                            opacity: "0.1",
                          }}
                        >
                          <input
                            disabled
                            id="radio-2"
                            value={crypto}
                            onClick={(e) => setRdo("crypto")}
                            name="radio"
                            type="radio"
                            checked={rdo == "crypto" ? true : false}
                          />
                          <label for="radio-2" class="radio-label">
                            <TranslatesContent contentKey="gateway-b" />
                          </label>
                        </div>
                      </div> */}
                          <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 mt-4">
                              <label htmlFor="exampleFormControlInput1" className="cashierNewTitel">
                                <TranslatesContent contentKey="deposit-amount" />
                              </label>
                              <div className="d-flex">
                                <select
                                  className="form-select me-2"
                                  value={selectedCurrency}
                                  onChange={handleCurrencyChange}
                                  style={{width:'100px'}}
                                >
                                  <option value="USD">USD</option>
                                  <option value="LKR">LKR</option>
                                </select>
                                <input
                                  onInput={(e) => validateNumberInput(e.target)}
                                  type="number"
                                  value={amount}
                                  onChange={(e) => {
                                    calculateBonuses(e.target.value);
                                  }}
                                  className={`form-control ${_.isEmpty(errors) ? '' : errors?.amount ? 'is-invalid' : 'is-valid'}`}
                                  placeholder=""
                                  onFocus={handleFocus}
                                />
                              </div>
                              {errors?.amount?.message && (
                                <div className="invalid-feedback">
                                  {errors.amount.message}
                                </div>
                              )}
                              <div id="emailHelp" className="form-text text-start" style={{ color: '#818181',marginRight:'10px' }}>
                                <TranslatesContent contentKey="min-max-limit" />: 50 / 5,000
                              </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <BonusOffer name={"deposit-bonus"} /> */}
              <div class="col-lg-6 offset-xl-1 offset-lg-2 offset-md-2 offset-sm-0 offset-0 mt-2">
                <div class="row">
                  <div class="col-12">
                    <div class="row my-3  my-xl-4 my-lg-4 my-md-4 my-sm-4">
                      <div class="col-12">
                        <label
                          for="exampleFormControlInput1"
                          class="cashierNewTitel"
                        >
                          {/* <TranslatesContent contentKey="bonus" /> */}
                        </label>
                      </div>
                      {/* <div class="col-12">
                        <input
                          type="checkbox"
                          class="my-checkbox"
                          data-val="1"
                          checked={BonusStore}
                          onChange={(e) => {
                            bonusOfferSwitch(e.target.checked);
                          }}
                        />
                        <TranslatesContent contentKey="activate" />
                      </div> */}
                    </div>
                    {/* {BonusStore ? (
                      <div>
                        <div class="row">
                          <div class="col-12 col-lg-5 bonusResultLeft">
                            <TranslatesContent contentKey="bonus-amount" />:
                          </div>
                          <div class="col-12 col-lg-7 bonusResultRight">
                            {reBonus}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-lg-5 bonusResultLeft">
                            <TranslatesContent contentKey="withdrawal-turnover" />
                            :
                          </div>
                          <div class="col-12 col-lg-7 bonusResultRight">
                            {reTurn}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {paymentErr && (
                      // <div
                      //   style={{ marginTop: "30px" }}
                      //   className="alert alert-danger"
                      // >
                      //   {paymentErr}
                      // </div>
                      <div class="alert alert-danger" role="alert">
                        {paymentErr}
                      </div>
                    )} */}
                    <div class="row mt-4">
                      <div class="col-6 col-lg-4 col-md-4 col-sm-4">
                        <button
                          type="submit"
                          class="newcashierButton"
                          disabled={load}
                        >
                          {load ? (
                            "Processing..."
                          ) : (
                            <TranslatesContent contentKey="submit" />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        {/* whatsapp button */}
        {/* <WhatsappButton /> */}
      </div>
    </div>
  );
};

export default UpiPayment;
