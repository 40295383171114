import axios from "axios";
import { IMAGE_BUCKET_URL, POINTS_URL, REDEEM_POINTS, REDEEM_DATES} from "../../../DataStore/ApiConstant";
import { useEffect,useState } from "react";
import OdometerDisplay from '../Loyalty/odometer';
import Odometer from 'react-odometerjs';
import { useRedeemDates } from "../../../services/cashier";
import { formatDateToDesiredFormat } from "../../../utils";
var moment = require("moment")

const LoyaltyContent = ({ openPopup, login }) => {

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // const redeemDates = useRedeemDates();

  // let startDate = redeemDates.isLoading && redeemDates?.data?.power_play_start[0]?.value
  // let endDate = redeemDates.isLoading && redeemDates?.data?.power_play_end[0]?.value 

  console.log(startDate);
  console.log(endDate);
  
  const [value, setValue] = useState(0);
  const [value2, setValue2] = useState(0);

  const [errmsg, setErrmsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [redeemStatus, setRedeemStatus] = useState(null);
  let points = 0 

  const redeemPoints = () => {
    if(points <= 0){
      setErrmsg("You have 0 points")
      setTimeout(() => {
        setErrmsg("");
      }, 3000)
    }else{
      axios
      .get(REDEEM_POINTS)
      .then((resp) => {
        const currentDate = new Date();
        const milliseconds = currentDate.getMilliseconds()
        setRedeemStatus(milliseconds)
        console.log(resp);
        setSuccessMsg("Points redeemed successfully")
        setTimeout(() => {
          setSuccessMsg("");
        }, 3000);
  
      })
      .catch((err) => {
        console.error('Error', err)
      });
    }

  }

  const fetchPoints = (startDate, endDate) => {
    axios
      .get(POINTS_URL + `?startdate=${startDate}&enddate=${endDate}`)
      .then((resp) => {
        console.log(resp?.data?.points);
        if (resp?.data?.points > 0) {
          const points = Math.floor(resp?.data?.points);
          setValue(points);
          setValue2(Math.floor(points / 2000));
        } else {
          console.error('Points data is not available');
        }
      })
      .catch((err) => {
        console.error('Error fetching points', err);
      });
  };

  useEffect(() => {
    const fetchRedeemDates = async () => {
      try {
        const resp = await axios.get(REDEEM_DATES);
        const data = resp.data;
  
        const startDateValue = data?.power_play_start[0]?.value;
        const endDateValue = data?.power_play_end[0]?.value;
        const lastRedeemDateValue = data?.power_play_last_redeem_date[0]?.result_date;
  
        const startDate = new Date(startDateValue);
        const endDate = new Date(endDateValue);
        const lastRedeemDate = new Date(lastRedeemDateValue);
  
        let formattedStartDate = formatDateToDesiredFormat(startDateValue);
  
        if (redeemStatus && lastRedeemDate >= startDate && lastRedeemDate <= endDate) {
          formattedStartDate = formatDateToDesiredFormat(lastRedeemDateValue);
        }
  
        const formattedEndDate = formatDateToDesiredFormat(endDateValue);
  
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
  
        fetchPoints(formattedStartDate, formattedEndDate);
      } catch (err) {
        console.error('Error fetching redeem dates', err);
        // Optional: set an error state here if needed
      }
    };
  
    fetchRedeemDates();
  }, [redeemStatus, formatDateToDesiredFormat, fetchPoints]);
  
  
  
  return (
    <>
      <>
    {/* <img src="images/ppp-banner.jpg" class="img-fluid mx-auto d-block " alt=""> */}
    <div className="row">
      <div className="col-6">
        <a href="#ppp">
          <img
            src={IMAGE_BUCKET_URL+"newPower-1.jpg"}
            className="img-fluid mx-auto d-block "
            alt=""
          />
        </a>
      </div>
      <div className="col-6">
        <a href="#jd">
          <img
            src={IMAGE_BUCKET_URL+"newPower-2.jpg"}
            className="img-fluid mx-auto d-block "
            alt=""
          />
        </a>
      </div>
    </div>
  <div
    className="container-fluid mt-lg-5"
    data-aos="fade-down"
    data-aos-easing="linear"
    data-aos-duration={1500}
  >
    <div className="row">
      <div
        className="col-10 offset-1 p-3"
        style={{ backgroundColor: "#0c0c0c", borderRadius: 10 }}
        id="ppp"
      >
        <div className="row">
          <div className="col-12 mainTitel">Ballysbet Power Play</div>
          {/* <div className="col-12">
            <h3>GOAL</h3>
          </div> */}
          {/* <div className="col-12 contentNewBox">
          In view of “BALLYSBET POWER PLAY”, we aim to maximise players of Ballysbet.com to play */}
          {/* continuously on Ballysbet.com (Active and Inactive Players) */}
            {/* Players can earn PPP Points on every valid bet in all of the games.
            From 30th May to 30th June These PPP Points can be exchanged for
            cash and other rewards with additional incentives to continue
            playing and enjoying the experience. */}
          {/* </div> */}
        </div>
        <div className="row">
          {/* <div className="col-12">
            <h3>Action plan</h3>
          </div> */}
          <div className="col-12 contentNewBox">
          Players will earn points though their Game-Play, upon reaching certain points, players
will be able to unlock their 01 stage of Rewards System
            {/* Players can earn PPP Points on every valid bet in all of the games.
            From 30th May to 30th June These PPP Points can be exchanged for
            cash and other rewards with additional incentives to continue
            playing and enjoying the experience. */}
          </div>
        </div>
        <div className="row">
          <div className="col-10 offset-1">
            <table
              className="table table-dark table-striped"
              style={{ borderCollapse: "separate" }}
            >
              <thead>
                <tr>
                  <th scope="col">POINTS</th>
                  <th scope="col">NON CASH BONUS</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>75,000</td>
                  <td>2.5 MILLION</td>
                </tr>
                <tr>
                  <td>140,000</td>
                  <td>5 MILLION</td>
                </tr>
                <tr>
                  <td>200,000</td>
                  <td>7.5 MILLION</td>
                </tr>
                <tr>
                  <td>250,000</td>
                  <td>10 MILLION</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-12 p-3">
            <div className="row">
              <div className="col-12 mb-3">
                <h3>You have gained this many Points</h3>
              </div>
              <div className="row my-3">
                <div className="col-6 text-end align-self-center">
                  <h4>Your Current Power Play Points </h4>
                </div>
                <div className="col-6">
                  <div className="pointsAmount odometer" id="odometer">
                  <Odometer value={value} format="(ddd)"/>

                  </div>
                  {/* <div class="row">
                                  <div class="col-12 odometer" id="odometer">0</div>
                              </div> */}
                </div>
              </div>
              <div className="col-12 my-3 text-center">
                <h4>To Redeem, Please Press </h4>
              </div>
              <div className="col-12 text-center">
                <button
                  onClick={() => {login ? redeemPoints() : openPopup("login")}}
                  type="button"
                  className="btn btn-warning align-middle joinTopbutton"
                >
                  REDEEM NOW
                </button>
              </div>
              {errmsg && (
                  <div
                    style={{ marginTop: "30px" }}
                    className="alert alert-danger"
                  >
                    {errmsg}
                  </div>
                )}
                {successMsg && (
                  <div
                    style={{ marginTop: "30px" }}
                    className="alert alert-success"
                  >
                    {successMsg}
                  </div>
                  // <div className="alert alert-success">{successMsg}</div>
                )}
            </div>
          </div>
        </div>
        <div className="row">

        </div>
        <div className="row">
          <div className="col-10 offset-1 mt-3">
            <p>Terms and Conditions</p>
            <ol>
              <li className="mb-1">
              Once you achieve the required points, you can collect your gift
              </li>
              <li className="mb-1">
              If you withdraw before reaching the required points, you will not be entitled to collect the gift.
              </li>
              <li className="mb-1">
              Upon completing the required points, you have the option to either collect the entitled gift or upgrade to the next level. If you choose to withdraw, you will have to start all over again from the beginning.
              </li>
              <li className="mb-1">
              You are eligible to try Power Play multiple times upon completing a level of Power Play.
              </li>
              <li className="mb-1">
              Management's decision is final.
              </li>
              {/* <li className="mb-1">
                Has been the industry's standard dummy text ever since
              </li>
              <li className="mb-1">
                Dummy text of the printing and typesetting industry
              </li> */}
            </ol>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  {/* <div
    className="container-fluid mt-lg-5"
    data-aos="fade-down"
    data-aos-easing="linear"
    data-aos-duration={1500}
  >
    <div className="row">
      <div
        className="col-10 offset-1 p-3"
        style={{ backgroundColor: "#0c0c0c", borderRadius: 10 }}
        id="jd"
      >
        <div className="row">
          <div className="col-12 mainTitel">Jackpot Draw</div>
          <div className="col-12">
            <h3>Introduction</h3>
          </div>
          <div className="col-12 contentNewBox">
            Players can earn PPP Points on every valid bet in all of the games.
            These PPP Points can be exchanged for
            cash and other rewards with additional incentives to continue
            playing and enjoying the experience.
          </div>
          <div className="col-12 p-3">
            <div className="row">
              <div className="col-12 mb-3">
                <h3>You have Earn this Mega Points</h3>
              </div>
              <div className="row my-3">
                <div className="col-6 text-end align-self-center">
                  <h4>Your Mega Points, Earn Up to Date </h4>
                </div>
                <div className="col-6">
                  <div className="pointsAmount odometer2" id="odometer2">
                    <Odometer value={value2} format="(,ddd).dd"/>
                  </div>
                </div>
                <div className="col-12 text-center mt-3 align-self-center">
                  <h4>2000 Points = 1 Mega Point</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}
</>

      
    </>
  );
};

export default LoyaltyContent;
