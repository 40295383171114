import { connect } from "react-redux";
import { IMAGE_BUCKET_URL } from "../../DataStore/ApiConstant";
import Carousel from "react-bootstrap/Carousel";
import { IMGBUCKETURL, IMGBUCKETURLNEW } from "../../DataStore/ApiConstant";
import TranslatesContent from "../../DataStore/Languages/translatesContent";

const InnerBanner = ({ siteImages, openLoginPopup, login }) => {
  siteImages && siteImages.images && console.log(siteImages.images.promotions1);
  return (
    <div
      className="container-fluid mt-lg-3"
      data-aos="fade-down"
      data-aos-easing="linear"
      data-aos-duration="1500"
    >

    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    siteImages: state.siteImages,
  };
};

export default connect(mapStateToProps)(InnerBanner);
