import axios from "axios";
import { useState,useEffect } from "react";
import { REGISTER_USERDATA_URL } from "../../../../DataStore/ApiConstant";
import TranslatesContent from "../../../../DataStore/Languages/translatesContent";

const GeneralInfo = ({ setFormType, country, phone }) => {
  const [fullname, setFullname] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [promocode, setPromocode] = useState("");
  const [gender, setGender] = useState("male");
  const [username, setUsername] = useState("");
  const [initPassword, setPassword] = useState("");
  const [confirmedPass, setConfirmedPass] = useState("");

  const [nameErr, setNameErr] = useState("");
  const [mobileNumErr, setMobileNumErr] = useState("");
  const [dobErr, setDobErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [promoCodeErr, setPromoCodeErr] = useState("");
  const [usernameErr, setUsernameErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");

  const countryVal = country;
  const countryFormatted = countryVal.split("-")[0];

  const phoneVal = phone;
  let phoneFormatted = countryVal.split("-")[1] + phoneVal;
  phoneFormatted= "+"+phoneFormatted
  const [formErr, setFormErr] = useState("");

  const verifyPassword = (password, what) => {
    // switch (what) {
      // case "verify":
      //   if (initPassword === password) {
      //     console.log(initPassword);
      //     setConfirmedPass(password);
      //   }
      //   break;
      // default:
        setPassword(password);
        // break;
    // }
  };

  const submitGeneralInfo = () => {
    setFormErr("")
    setPasswordErr("") 
    fullname == "" ? setNameErr("Name Required") : setNameErr("")
    phoneFormatted == "" ? setMobileNumErr("Phone number not valid") : setMobileNumErr("")
    dob == "" ? setDobErr("Date of birth invalid") : setDobErr("")
    // email == "" ? setEmailErr("Email is required") : setEmailErr("")
    // promocode == "" ? setPromoCodeErr("Promo Code invalid") : setPromoCodeErr("")
    username == "" ? setUsernameErr("Username Required") : setUsernameErr("")
    confirmedPass == "" ? setPasswordErr("Password invalid") : setPasswordErr("")
    initPassword == "" ? setPasswordErr("Password invalid") : setPasswordErr("")
    // initPassword !== confirmedPass ? setPasswordErr("Password doesn't match") : setPasswordErr("")
    
    if (fullname == "" || countryFormatted == "" || phoneFormatted =="" || dob == "" || username =="" || confirmedPass =="" || initPassword=="")  return
    if(initPassword !== confirmedPass){
      return setPasswordErr("Password doesn't match") 
    }

    const formData = new FormData();
    formData.append("name", fullname);
    formData.append("country", countryFormatted);
    formData.append("phone", phoneFormatted);
    formData.append("dob", dob);
    formData.append("email", email);
    formData.append("promocode", promocode);
    formData.append("gender", gender);
    formData.append("usname", username);
    formData.append("passw", confirmedPass);
    formData.append("btag", "");
    formData.append("referral", "");

    axios
      .post(REGISTER_USERDATA_URL, formData)
      .then((resp) => {
        // console.log(resp.data === "err");
        if(resp.data === "done"){
          return setFormType("success");
        }
       if( resp.data=== "err") {
          return setFormErr("Fill out all the required fields");
       }
       if( resp.data=== "age") {
          return setFormErr("Age must be grater than 18");
       }
       if( resp.data=== "Username is already Taken") {
          return setFormErr("Username is already Taken");
       }

        if (resp.data !== "done" || resp.data !== "success") {
          if (resp.data === "Acc false") {
            setFormErr("Something went wrong!");
          } else {
            setFormErr(resp.data);
          }
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    if(localStorage.getItem("promocode")){
      let promoCode = localStorage.getItem("promocode")
      setPromocode(promoCode)
    }
  },[])
  return (
    <div className="general-info-form-wrapper">
      <div
        className="col-12 subTitel text-center"
        style={{ color: "#FCB503", fontSize: "1.35rem" }}
      >
        <TranslatesContent contentKey="welcome-registration" />
      </div>
      <form className="row">
        <div className="row mt-2">
          <div className="col-md-6">
            <label htmlFor="full-name">
              <TranslatesContent contentKey="fullname" />*
            </label>
            <input
              type="text"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
              className="form-control"
            />
          <div className="general-form-error mt-3" style={{ color: "red" }}>
            {nameErr && nameErr}
          </div>
          </div>
          <div className="col-md-6">
            <label htmlFor="full-name">
              <TranslatesContent contentKey="join-now-country" /> *
            </label>
            <input
              type="text"
              value={countryFormatted}
              readOnly
              className="form-control"
            />

          </div>
          
        </div>
        <div className="row mt-2">
          <div className="col-md-6">
            <label htmlFor="full-name">
              <TranslatesContent contentKey="mobile-number" /> *
            </label>
            <input
              type="text"
              readOnly
              value={phone}
              className="form-control"
            />
          <div className="general-form-error mt-3" style={{ color: "red" }}>
            {mobileNumErr && mobileNumErr}
          </div>
          </div>
          <div className="col-md-6">
            <label htmlFor="full-name">
              {" "}
              <TranslatesContent contentKey="date-of-birth" /> *
            </label>
            <input
              type="date"
              onChange={(e) => setDob(e.target.value)}
              className="form-control"
            />
          <div className="general-form-error mt-3" style={{ color: "red" }}>
            {dobErr && dobErr}
          </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-4">
            <label htmlFor="full-name">
              {" "}
              <TranslatesContent contentKey="email" />
            </label>
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              className="form-control"
            />
          <div className="general-form-error mt-3" style={{ color: "red" }}>
            {emailErr && emailErr}
          </div>
          </div>
          <div className="col-md-4">
            <label htmlFor="full-name">
              <TranslatesContent contentKey="promo-code" />
            </label>
            <input
              onChange={(e) => setPromocode(e.target.value)}
              value={promocode}
              type="text"
              className="form-control"
            />
          <div className="general-form-error mt-3" style={{ color: "red" }}>
            {promoCodeErr && promoCodeErr}
          </div>
          </div>
          <div className="col-md-4">
            <label htmlFor="full-name">
              {" "}
              <TranslatesContent contentKey="gender" />
            </label>
            <select
              onChange={(e) => setGender(e.target.value)}
              name=""
              id=""
              className="form-select"
            >
              <option value={"male"}>
                <TranslatesContent contentKey="male" />
              </option>
              <option value={"female"}>
                <TranslatesContent contentKey="female" />
              </option>
            </select>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-4">
            <label htmlFor="full-name">
              <TranslatesContent contentKey="username-register" />*
            </label>
            <input
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              className="form-control"
            />
          <div className="general-form-error mt-3" style={{ color: "red" }}>
            {usernameErr && usernameErr}
          </div>
          </div>
          <div className="col-md-4">
            <label htmlFor="full-name">
              <TranslatesContent contentKey="password-register" />*
            </label>
            <input
              type="password"
              onChange={(e) => verifyPassword(e.target.value, "init")}
              className="form-control"
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="full-name">
              <TranslatesContent contentKey="repeat-password" />*
            </label>
            <input
              onChange={(e) => setConfirmedPass(e.target.value)}
              type="password"
              className="form-control"
            />
          <div className="general-form-error mt-3" style={{ color: "red" }}>
            {passwordErr && passwordErr}
          </div>
          </div>
        </div>
      </form>
      <div className="general-form-error mt-3" style={{ color: "red" }}>
        {formErr && formErr}
      </div>

      <div className="text-center mt-4">
        <button className="joinTopbutton" onClick={(e) => submitGeneralInfo()}>
          <TranslatesContent contentKey="submit-cashier" />
        </button>
      </div>

    </div>
  );
};

export default GeneralInfo;
