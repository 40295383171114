import axios from "axios";
import { useEffect, useState } from "react";
import {
  BASE_URL,
  FUND_TRANSFER_TYPE_URL,
  SPORT_LOGIN_URL,
} from "../../DataStore/ApiConstant";
import { connect } from "react-redux";
import { TOAST_CONFIG } from "../../constants";
import WhatsappButton from "./whatsappButton";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { getFormData, validateNumberInput } from "../../utils";
import { fundTransferTypes } from "../../constants/";
import { toast } from "react-toastify";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import { type } from "@testing-library/user-event/dist/type";
import { fundTransfer } from "../../DataStore";
// import { UserData } from "../../DataStore";
import { UserData } from "../../DataStore";

let validator = {
  amount: yup
    .number()
    .required("Please provide amount.")
    .min(1, "Must be greater than 0")
    .typeError("Amount must be a number"),
};
// console.log(fundTransferTypes);
const FundTransfer = ({
  fundsTransfer,
  getUserData,
  getFUndTransfers,
  goToCashier,
}) => {
  useEffect(() => {
    getUserData();
    getFUndTransfers();
  }, []);
  const schema = yup.object().shape(validator);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [transferTypes, setTransType] = useState("");
  const [tranFrom, setTransFrom] = useState("0");
  const [tranTo, setTransTo] = useState("0");
  const [transAmt, setTransAmt] = useState(0);
  const [reqType, setReqType] = useState("sp-dep");
  const [loader, setLoader] = useState(false);
  const [selectError, setSelectError] = useState(false);
  const [load, setLoad] = useState(false);
  const [spLoad, setSpLoad] = useState(false);
  const [dpLoad, setDpLoad] = useState(false);
  const [paymentErr, setPaymentErr] = useState(false);

  const [errmsg, setErrmsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const sportsLogin = () => {
    setSpLoad(true);
    axios
      .get(SPORT_LOGIN_URL)
      .then((resp) => {
        window.open(resp.data.data.url, "_blank", "noreferrer");
        setSpLoad(false);
      })
      .catch((err) => {
        // toast.error(err?.data?.resp || "err", TOAST_CONFIG);
        setErrmsg(err?.data?.resp);
        setSpLoad(false);
      });
  };
  const setTransFromValue = (e) => {
    setTransFrom(e)
    checkAplicable(e,tranTo)
  }
  const setTransToValue = (e)=>{
    setTransTo(e)
    checkAplicable(tranFrom,e)

  }
  const checkAplicable = (tranF,tranT) => {
    if(tranF==="1" && tranT==="2"){
     setErrmsg("Bonus not applicable")
    }else{
      setErrmsg("")
    }
  }
  const openDeposit = async (type) => {
    setDpLoad(true);
    try {
      await goToCashier(type);
    } catch (error) {
      console.error(error);
    }
    setDpLoad(false);
  };
  let submitHandler = async (fundTranferObj) => {
    setLoad(true);
    if (paymentErr) {
      setLoad(false);
      return;
    }
    if (tranFrom == "0" || tranTo == "0") {
      setLoad(false);

      return setSelectError(true);
    }
    setSelectError(false);
    fundTranferObj = fundTransferTypes[tranFrom][tranTo]["type"] != ""  ? 
        { ...fundTranferObj,  type: fundTransferTypes[tranFrom][tranTo]["type"], } : fundTranferObj;
    axios.post( BASE_URL + fundTransferTypes[tranFrom][tranTo]["url"], getFormData(fundTranferObj) ).then(({data}) => {
      if(tranFrom==1 && tranTo==2){
        setErrmsg("Bonus not aplicable")
      }
        if (data.resp == 'invalid amount') {
          setErrmsg(data.resp);
        } 
        else {
          if (data.resp == "pok") {
          
              if (data.data == 'Success') {
                setSuccessMsg(data.data);
                getUserData();
                getFUndTransfers(); 
                reset(); 
            } else {
              setErrmsg(data.data);
            }
          } else {
            setErrmsg(data.resp);
          }
        }
      }).catch((err) => {
        setErrmsg(err?.data?.resp);
      }).finally(() => {
        setTimeout(() => {
          setErrmsg("");
          setSuccessMsg("")
        }, 3000);
        setLoad(false);
      });
  };

  const handleFocus = (event) => {
    event.target.select();
  };


  useEffect(() => {
    axios
      .get(FUND_TRANSFER_TYPE_URL)
      .then((resp) => {
        setTransType(resp.data);
      })
      .catch((err) => {});
  }, []);

  const ValidateDepositAmount = (reAmount) => {
    setSuccessMsg("");
    if (reAmount != "" && (reAmount < 500 || reAmount > 70000)) {
      setPaymentErr("Please enter amount between min and max limit");
    } else {
      // checkAplicable()
      setPaymentErr(false);
    }
  };

  return (
    <div class="row p-4">
      <div class="col-12 col-lg-10 col-md-8 col-sm-8 col-xl-10 offset-xl-1 offset-lg-1 offset-md-2 offset-sm-2 offset-0">
        <div class="row">
          <div class="col-12 col-6 col-lg-6 col-md-6 col-sm-12">
            <table class="table table-dark table-striped">
              <tbody>
                <tr>
                  <th
                    scope="row"
                    style={{
                      color: "#25ac5b",
                      fontSize: "16px",
                      fontWeight: "300",
                    }}
                  >
                    <TranslatesContent contentKey="wallet-name" />
                  </th>
                  <th
                    style={{
                      color: "#25ac5b",
                      fontSize: "16px",
                      fontWeight: "300",
                    }}
                  >
                    <TranslatesContent contentKey="balance" />
                  </th>
                  <th
                    style={{
                      color: "#25ac5b",
                      fontSize: "16px",
                      fontWeight: "300",
                    }}
                  >
                    <TranslatesContent contentKey="action" />
                  </th>
                </tr>
                <tr style={{ color: "white" }}>
                  <td scope="row">Sports Wallet</td>
                  <td>
                    {fundsTransfer &&
                      fundsTransfer.data &&
                      fundsTransfer.data.data.sports &&
                      new Intl.NumberFormat().format(
                        fundsTransfer.data.data.sports.balance
                      )}
                  </td>
                  <td>
                    <button
                      onClick={sportsLogin}
                      class="newcashierButton"
                      style={{ width: "100%" }}
                      disabled={spLoad}
                    >
                      {spLoad ? (
                        "Processing"
                      ) : (
                        <TranslatesContent contentKey="play-now" />
                      )}
                    </button>
                  </td>
                </tr>
                <tr style={{ color: "white" }}>
                  <td scope="row">
                    <TranslatesContent contentKey="cash-wallet(without-bonus)" />
                  </td>
                  <td>
                    {fundsTransfer &&
                      fundsTransfer.data &&
                      fundsTransfer.data.data.wallet &&
                      new Intl.NumberFormat().format(
                        fundsTransfer.data.data.wallet.balance
                      )}
                  </td>
                  <td>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        openDeposit("deposit");
                      }}
                      class="newcashierButton"
                      style={{ width: "100%" }}
                      disabled={dpLoad}
                    >
                      {dpLoad ? (
                        "Processing"
                      ) : (
                        <TranslatesContent contentKey="deposit" />
                      )}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 col-6 col-lg-6 col-md-6 col-sm-12">
            <div class="row">
              <form className="row" onSubmit={handleSubmit(submitHandler)}>
                <div class="mb-3 col-6 col-lg-6 col-md-6 col-sm-6">
                  <label for="exampleFormControlInput1" class="cashierNewTitel">
                    <TranslatesContent contentKey="transfer-from" />
                  </label>
                  <select
                    onChange={(e) => {setTransFromValue(e.target.value)}}
                    value={tranFrom}
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option value="0">
                      -<TranslatesContent contentKey="select" />-
                    </option>
                    {transferTypes &&
                      transferTypes.map((transType) => {
                        return transType.id == 3 ? null : (
                          <option key={transType.id} value={transType.id}>
                            {transType.type}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div class="mb-3 col-6 col-lg-6 col-md-6 col-sm-6">
                  <label for="startDate" class="cashierNewTitel">
                    <TranslatesContent contentKey="transfer-to-new" />
                  </label>
                  <select
                    onChange={(e) => {setTransToValue(e.target.value)}}
                    value={tranTo}
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option value="0">
                      -<TranslatesContent contentKey="select" />-
                    </option>
                    {transferTypes &&
                      transferTypes.map((transType) => {
                        if (
                          tranFrom &&
                          tranFrom != 0 &&
                          transType.id != tranFrom
                        ) {
                          if (
                            (tranFrom == 2 && transType.id == 3) ||
                            (tranFrom == 3 && transType.id == 2)
                          ) {
                          } else {
                            return transType.id == 3 ? null : (
                              <option key={transType.id} value={transType.id}>
                                {transType.type}
                              </option>
                            );
                          }
                        }
                      })}
                  </select>
                  {selectError}
                </div>
                {selectError == true ? (
                  <p style={{ color: "red" }}>
                    <TranslatesContent contentKey="please-select" />
                  </p>
                ) : (
                  " "
                )}
                <div class="mb-3 col-12 col-lg-6 col-md-6 col-sm-6">
                  <label for="startDate" class="cashierNewTitel">
                    <TranslatesContent contentKey="transfer-amount" />
                  </label>
                  <input
                    onInput={(e) => validateNumberInput(e.target)}
                    type="number"
                    class={`form-control ${
                      _.isEmpty(errors)
                        ? ""
                        : errors?.amount
                        ? "is-invalid"
                        : "is-valid"
                    }`}
                    onFocus={handleFocus}
                    {...register("amount")}
                    onChange={(e) => {
                      ValidateDepositAmount(e.target.value);
                    }}
                    placeholder=""
                  />
                  {errors?.amount?.message ? (
                    <div className="invalid-feedback">
                      {errors?.amount?.message}
                    </div>
                  ) : null}
                  <div
                    id="emailHelp"
                    class="form-text text-start"
                    style={{ color: "#555759" }}
                  >
                    <TranslatesContent contentKey="min-max-limit" />: 500.00 -
                    70,000.00
                  </div>
                </div>
                {successMsg !='' ? (
                  <div
                    style={{ marginTop: "30px" }}
                    className="alert alert-success"
                  >
                    {successMsg}
                  </div>
                  // <div className="alert alert-success">{successMsg}</div>
                ):null}
                {/* bank payment error */}

                {errmsg !='' ? (
                  <div
                    style={{ marginTop: "30px" }}
                    className="alert alert-danger"
                  >
                    {errmsg}
                  </div>
                  // <div class="alert alert-danger" role="alert">
                  //   {errmsg}
                  // </div>
                ):null}
                {paymentErr && (
                  <div
                    style={{ marginTop: "30px" }}
                    class="alert alert-danger"
                    role="alert"
                  >
                    {paymentErr}
                  </div>
                )}
                <div class="mb-3 col-6 col-lg-4 col-md-4 col-sm-4">
                  <label
                    for="startDate"
                    class="cashierNewTitel"
                    style={{ width: "100%" }}
                  >
                    &nbsp;
                  </label>
                  <button
                    disabled={load}
                    type="submit"
                    className="newcashierButton"
                  >
                    {load ? "Processing" : "TRANSFER"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    fundsTransfer: state.FundTransfers,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getFUndTransfers: () => dispatch(fundTransfer()),
    getUserData: () => dispatch(UserData()),
  };
};
// const mergeProps = (stateProps, dispatchProps, ownProps) => {
//     return Object.assign({}, ownProps, stateProps, dispatchProps)
//   }

export default connect(mapStateToProps, mapDispatchToProps)(FundTransfer);
