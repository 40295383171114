import { connect } from "react-redux";
// import WhatsappButton from "./whatsappButton";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import {
  useTransctionHistory,
  useTransactionMethodsNew,
} from "../../services/cashier";
import { transactionHistory } from "../../constants";
import { formatString } from "../../utils";

var moment = require("moment"); // require
function cb(start, end) {
  alert(start);
  // $("#reportrange span").html(
  //   start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY")
  // );
  // alert(start.format("MMMM D, YYYY"));
  // alert(end.format("MMMM D, YYYY"));
}

let mappig={
  "*":{"cr_db":"*","type":""},
  "credit":{"cr_db":"credit","type":""},
  "debit":{"cr_db":"debit","type":""},
  "101":{"cr_db":"*","type":"101"},
  "102":{"cr_db":"*","type":"102"}
}
const TransferHistory = () => {
  const [rdo, setRdo] = useState("*");
  const [filter, setFilter] = useState(transactionHistory);
  const dataHistory = useTransctionHistory(filter);
  // const transactionMethods = useTransactionMethodsNew();
  const [date, setDate] = useState(0);
  
  const { handleSubmit,  formState: { errors }} = useForm();

  let submitHandler = async (Obj) => {
    let filter = {
      ...transactionHistory,
      // type: Obj.method,
      type: mappig[rdo].type,
      cr_db: mappig[rdo].cr_db,
      startDate:moment().subtract(date, 'days').startOf('day').format('YYYY-MM-DD')+` 00:00:00`,
      endDate: moment().format('YYYY-MM-DD')+` 23:59:00`,
    };
    // console.log(filter);
    setFilter(filter);
    dataHistory.refetch();
  };

  return (
    <div class="row p-4">
      <div class="col-12 col-lg-10 col-md-8 col-sm-8 col-xl-10 offset-xl-1 offset-lg-1 offset-md-2 offset-sm-2 offset-0">
        <div class="row">
          <form class="row" onSubmit={handleSubmit(submitHandler)}>
            {/* search area */}
            <div class="mb-3 col-12 col-lg-3 col-md-12 col-sm-12">
              <label for="startDate" class="cashierNewTitel">
                <TranslatesContent contentKey="transaction-date" />
              </label>

              <div
                // id="reportrange"
                style={{
                  cursor: "pointer",
                  color: "white",
                  padding: "2px 5px",
                  width: "100%",
                  display: "flex",
                  fontSize: "12px",
                }}
              >
                {/* <span class="fa fa-calendar"></span> */}
                <select value={date} name="cars" id="date" className="form-control" onChange={(e)=>setDate(e.target.value)}>
                  <option value="0">Today</option>
                  <option value="1">Yesterday</option>
                  <option value="2">Last 2 Days</option>
                  <option value="3">Last 3 Days</option>
                  <option value="4">Last 4 Days</option>
                  <option value="5">Last 5 Days</option>
                  <option value="6">Last 6 Days</option>
                  <option value="7">Last 7 Days</option>
                </select>
              </div>
            </div>
            <div class="mb-3 col-12 col-lg-7 col-md-8 col-sm-12">
              <div class="d-flex flex-wrap">
                <div class="">
                  <label for="startDate" class="cashierNewTitel">
                    &nbsp;
                  </label>
                  <div class="radio">
                    <input
                      id="radio-4"
                      onChange={() => setRdo("*")}
                      name="radio"
                      type="radio"
                      checked={rdo == "*" ? true : false}
                    />
                    <label for="radio-4" class="radio-label">
                      <TranslatesContent contentKey="all" />
                    </label>
                  </div>
                </div>
                <div class="">
                  <label for="startDate" class="cashierNewTitel">
                    &nbsp;
                  </label>
                  <div class="radio">
                    <input
                      onChange={() => setRdo("credit")}
                      id="radio-5"
                      name="radio"
                      type="radio"
                      checked={rdo == "credit" ? true : false}
                    />
                    <label for="radio-5" class="radio-label">
                      <TranslatesContent contentKey="deposit-simple" />
                    </label>
                  </div>
                </div>
                <div class="">
                  <label for="startDate" class="cashierNewTitel">
                    &nbsp;
                  </label>
                  <div class="radio">
                    <input
                      onChange={() => setRdo("debit")}
                      id="radio-6"
                      name="radio"
                      type="radio"
                      checked={rdo == "debit" ? true : false}
                    />
                    <label for="radio-6" class="radio-label">
                      <TranslatesContent contentKey="withdraw-simple" />
                    </label>
                  </div>
                </div>
                <div class="">
                  <label for="startDate" class="cashierNewTitel">
                    &nbsp;
                  </label>
                  <div class="radio">
                    <input
                      onChange={() => setRdo("101")}
                      id="radio-7"
                      name="radio"
                      type="radio"
                      checked={rdo == "101" ? true : false}
                    />
                    <label for="radio-7" class="radio-label">
                      Bonus
                      {/* <TranslatesContent contentKey="withdraw-simple" /> */}
                    </label>
                  </div>
                </div>
                <div class="">
                  <label for="startDate" class="cashierNewTitel">
                    &nbsp;
                  </label>
                  <div class="radio">
                    <input
                      onChange={() => setRdo("102")}
                      id="radio-8"
                      name="radio"
                      type="radio"
                      checked={rdo == "102" ? true : false}
                    />
                    <label for="radio-8" class="radio-label">
                      Non Cash
                      {/* <TranslatesContent contentKey="withdraw-simple" /> */}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3 col-12 col-lg-2 col-md-4 col-sm-6">
              <label
                style={{ width: "100%" }}
                for="startDate"
                class="cashierNewTitel"
              >
                &nbsp;
              </label>
              <button type="submit" class="newcashierButton">
                <TranslatesContent contentKey="search" />
              </button>
            </div>

            {/* search area */}
            <div class="col-12 mt-3">
              <table class="table table-dark table-striped">
                <tbody>
                  <tr>
                    <th
                      scope="col"
                      style={{
                        color: "#25ac5b",
                        fontSize: "16px",
                        fontWeight: "300",
                      }}
                    >
                      <TranslatesContent contentKey="date-time" />
                    </th>
                    <th
                      cope="col"
                      style={{
                        color: "#25ac5b",
                        fontSize: "16px",
                        fontWeight: "300",
                      }}
                    >
                      <TranslatesContent contentKey="deposit-simple" />
                    </th>
                    <th
                      cope="col"
                      style={{
                        color: "#25ac5b",
                        fontSize: "16px",
                        fontWeight: "300",
                      }}
                    >
                      <TranslatesContent contentKey="withdraw-simple" />
                    </th>
                    <th
                      cope="col"
                      style={{
                        color: "#25ac5b",
                        fontSize: "16px",
                        fontWeight: "300",
                      }}
                    >
                      <TranslatesContent contentKey="remark" />
                    </th>
                  </tr>
                  {dataHistory.isSuccess && dataHistory.data.length == 0 ? (
                    <tr>
                      <td colSpan={4}>No data</td>
                    </tr>
                  ) : (
                    dataHistory?.data?.map((transfer, key) => {
                      return dataHistory.data.length == 0 ? (
                        <tr>
                          <td colSpan={3}>No data</td>
                        </tr>
                      ) : (
                        <tr key={key} style={{ color: "white" }}>
                          <td>
                            {moment(transfer.ad_time).format(
                              "DD-MM-YYYY hh:mm:ss"
                            )}
                          </td>
                          <td>
                            {transfer.cr_db == "credit"
                              ? new Intl.NumberFormat().format(transfer.amount)
                              : "-"}
                          </td>
                          <td>
                            {transfer.cr_db != "credit"
                              ? new Intl.NumberFormat().format(transfer.amount)
                              : "-"}
                          </td>
                          <td>N/A</td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </form>
        </div>
      </div>
    </div>

  );
};

const maStateTorops = (state) => {
  return {
    // transferHistory: state.TransferHistory,
  };
};

export default connect(maStateTorops)(TransferHistory);
