import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import HeaderComponent from "../Header/HeaderComponent";
import MainBanner from "../Banners/MainBanner";
import { useEffect, useRef, useState } from "react";
import { fundTransfer } from "../../DataStore";
import { connect } from "react-redux";
import { IMAGE_BUCKET_URL, IMGBUCKETURL } from "../../DataStore/ApiConstant";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import PromotionInfoPage from "../../Components/Promotions/PromotionInfoPage";
import { UserData } from "../../DataStore";
import { promotion } from "../../DataStore/atom";
import { useAtom } from "jotai";
import { usePromotions } from "../../services/promotions";

const Promotions = ({
  userData,
  getUserData,
  getFUndTransfers,
  openPopup,
  login,
}) => {
  const bottomRef = useRef(null);
  const history = useHistory();
  const checkLogin = () => {
    // !sessionStorage.getItem("login") && history.push("/");
    localStorage.getItem('login')&&history.push('/lobby')

  };
  const promotions = usePromotions()
  const [promo, setPromo] = useAtom(promotion);

  useEffect(() => {
    checkLogin();
    getUserData();

    getFUndTransfers();
  }, []);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);
  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const goToCashier = (type = "deposit") => {
    history.push("/cashier");
    scrollToBottom();
  };
  return (
    <>
      <div
        className="container-fluid mt-lg-5 py-5"
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration={1500}
      >
        {/* {JSON.stringify(promotions.data[0])} */}
        {promo == "" ? (
          <div className="row">
            {/* <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6 mb-4">
              <a onClick={() => setPromo("1")}>
                <div className="card">
                  <img
                    src={IMAGE_BUCKET_URL + "promo-1-new.jpg"}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">UP TO 7% CASH BACK</h5>
                    <p className="card-text">
                    CASHBACK IS A PROMOTIONAL OFFER PROVIDED BY BREEZE ONLINE CASINO. 
                    </p>
                  </div>
                </div>
              </a>
            </div> */}

            {promotions.isSuccess? (
              promotions?.data?.map((promotion, key) => {
                if(promotion.upcoming_promotion != 1){
                  return (
                    
                    <div
                    key={key}
                    className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6 mb-4"
                    >
                      <a onClick={() => setPromo(promotion)} style={{cursor: "pointer"}}>
                        <div className="card">
                          
                          <img
                            src={IMAGE_BUCKET_URL + promotion.img}
                            className="card-img-top"
                            alt="..."
                            />
                          <div className="card-body text-center">
                            <h5 className="card-title">
                              {promotion.heading}
                            </h5>
                            <p className="card-text">{promotion.subheading}</p>
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                }
                
              })
            ) : (
              <span>No data</span>
            )}
          {/* 
            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6 mb-4">
              <a onClick={() => setPromo("2")}>
                <div className="card">
                  <img
                    src={IMAGE_BUCKET_URL + "promo-2-new.jpg"}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">
                    WELCOME BONUS UP TO 50%
                    </h5>
                    <p className="card-text">
                    DEPOSIT FROM 1,000 INR UP TO 10,000 INR FOR WELCOME BONUS 50%. 
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6 mb-4">
              <a onClick={() => setPromo("3")}>
                <div className="card">
                  <img
                    src={IMAGE_BUCKET_URL + "promo-3-new.jpg"}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">BALLYSBREEZE 8% DEPOSIT BONUS</h5>
                    <p className="card-text">
                    EVERY DEPOSITING AMOUNT WILL ELIGIBLE TO GET 8% BONUS. 
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6 mb-4">
              <a onClick={() => setPromo("4")}>
                <div className="card">
                  <img
                    src={IMAGE_BUCKET_URL + "promo-4-new.jpg"}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">BALLYSBREEZE UP TO 10% REDEPOSIT BONUS</h5>
                    <p className="card-text">
                    THE REDEPOSIT BONUS WORKS SIMILARLY TO A DEPOSIT BONUS. 
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6 mb-4">
              <a onClick={() => setPromo("5")}>
                <div className="card">
                  <img
                    src={IMAGE_BUCKET_URL + "promo-5-new.jpg"}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">BALLYSBREEZE REFERRAL BONUS</h5>
                    <p className="card-text">
                    THE REFERRAL BONUS BENEFITS FOR THE EXISTING PLAYER
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6 mb-4">
              <a onClick={() => setPromo("6")}>
                <div className="card">
                  <img
                    src={IMAGE_BUCKET_URL + "promo-6-new.jpg"}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">
                    BALLYSBREEZE TAB OFFER
                    </h5>
                    <p className="card-text">
                    WIN VARIOUS PRIZES THROUGH A RANDOM DRAWING. 
                    </p>
                  </div>
                </div>
              </a>
            </div> */}
          </div>
        ) : (
          // <PromotionInfoPage goToCashier={goToCashier} openPopup={openPopup} />
        
          <div className="row">
            <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-12 offset-lg-2 offset-xl-2 offset-md-2 offset-sm-0 position-relative">
              <div className="position-absolute top-0 end-0">
                  <a href="#" onClick={(e) => { e.preventDefault(); setPromo(""); }}>
                    <img src="https://storage.googleapis.com/itonecdn/imgWeb/new-web/close.png" className="img-fluid" alt="" />
                  </a>
                </div>
              <div className="row">
                <div className="col-12">
                  <img
                    src={IMAGE_BUCKET_URL + promo?.img}
                    className="card-img-top"
                    alt="..."
                  />
                </div>
                <div className="col-12 my-4">
                  <h5 className="card-title">{promo?.heading || "N/A"}</h5>
                </div>
                <div className="col-12">
                  <p className="card-text mt-2 mb-2" >
                  <div dangerouslySetInnerHTML={{__html: promo?.body || "N/A"}}></div>
                  {/* {promo?.body || "N/A"} */}
                  </p>
                </div>
                <div className="col-12 my-4">
                  <div className="col-6 col-lg-4 col-md-4 col-sm-4 offset-lg-4 offset-md-4 offset-sm-4 offset-3">
                    {login ? (
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          goToCashier("deposit");
                        }}
                      >
                        <div className="newcashierButton"> DEPOSIT NOW</div>
                      </a>
                    ) : (
                      <a
                        onClick={() => {
                          openPopup("login");
                        }}
                      >
                        <div className="newcashierButton"> DEPOSIT NOW</div>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        
      </div>
    </>
  );
};

const maStateTorops = (state) => {
  return {
    fundsTransfer: state.FundTransfers,
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFUndTransfers: () => dispatch(fundTransfer()),
    getUserData: () => dispatch(UserData()),
  };
};

export default connect(maStateTorops, mapDispatchToProps)(Promotions);
