import { Fragment } from "react";
import { connect } from "react-redux";
import TranslatesContent from "../../DataStore/Languages/translatesContent";

const BeforeLoginContent = ({ openLoginPopup, LanguageContent }) => {
  return (
    <Fragment>
      <div class="p-2 bd-highlight">
        <a>
          <button
            type="button"
            class="btn btn-outline-light loginTopbutton w-100"
            // data-bs-toggle="modal"
            data-bs-target="#loginpopup"
            onClick={() => openLoginPopup("login")}
          >
            <TranslatesContent contentKey="login" />
          </button>
        </a>
      </div>
      {/* <div class="p-2 bd-highlight">
        <button
          type="button"
          class="btn btn-warning joinTopbutton"
          // data-bs-toggle="modal"
          data-bs-target="#joinpopup"
          onClick={() => openLoginPopup("register")}
        >
          <TranslatesContent contentKey="join-now" />
        </button>
      </div> */}
    </Fragment>
  );
};

const setStatetoProps = (state) => {
  return {
    LanguageContent: state.SiteLanguages,
  };
};

export default connect(setStatetoProps)(BeforeLoginContent);

// export default BeforeLoginContent;
