// src/atoms.js (or any other file you prefer)
import { atom } from "jotai";

export const promotion = atom("");
export const bonusStore = atom(false);
export const sportsSelected = atom(4);
export const preLoader = atom(false);
export const reRenderCashier = atom(1);
export const tableConfigs = atom({});


export const redirectToCashier = atom(null);