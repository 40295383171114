import axios from "axios";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useState,useEffect } from "react";
import { VERIFY_OTP_URL } from "../../../../DataStore/ApiConstant";
import TranslatesContent from "../../../../DataStore/Languages/translatesContent";
import RegisterButtonResend from "./RegisterButtonResend";
import OtpInput from 'react-otp-input';
import Countdown from 'react-countdown';
import {
  RECAPTCHA_SITEKEY,
  REGISTER_URL,
  FORGOT_PASSWORD_URL
} from "../../../../DataStore/ApiConstant";

const OtpForm = ({ setFormType, country, phone }) => {
  const initialTime = 600; // 10 minutes in seconds
  const [time, setTime] = useState(initialTime);
  const [otp, setOtp] = useState('');
  const [expireTime, setExpireTime] = useState(10.0);
  const [otpErr, setOtpErr] = useState("");
  const [resent, setResent] = useState("");

  const [registerErr, setRegisterErr] = useState(null);
  const [resetPassword, setResetPassword] = useState('');

  const verifyOtp = () => {
    const digits = String(otp).split('').map(Number)
    const formData = new FormData();
    formData.append("codeBox1", digits[0]);
    formData.append("codeBox2", digits[1]);
    formData.append("codeBox3", digits[2]);
    formData.append("codeBox4", digits[3]);
    formData.append("codeBox5", digits[4]);
    formData.append("codeBox6", digits[5]);

    axios.post(VERIFY_OTP_URL, formData).then((resp) => {
        if (resp.data === "success") {
          setFormType("generalinfo");
        } else {
          // setOtpErr(resp.data);
          setOtpErr("Invalid OTP");
        }
      }).catch((err) => {});
  };
  let msg={
    "already":"This Mobile number already exist..",
    "Invalid Mobile Number":"Invalid Mobile Number",
  }

  const reSendOtp = (e, token) => {
    setResent("")
    setResetPassword("")
    setOtpErr("")
    // console.log(token);
    // console.log(country);
    // console.log(phone);
    let data = {
      country: country,
      phone: phone,
      token: token,
    };
    let countryValue = country.split("-")[1];

    axios
      .post(REGISTER_URL, data)
          .then((resp) => {
            console.log();
            if (resp.data === "ok") {
              setTime(initialTime)
              // setFormType("otpform");
              setResent("Successfully Resent OTP")
              setTimeout(() => {
                setResent("")
              }, 3000);
            }else if (resp.data === "already") {
              axios.get(`${FORGOT_PASSWORD_URL}?code=${countryValue}&contact=${phone}&token=${token}`).then((resp) => {
                if(resp.data =='done'){
                  setResetPassword('This mobile number already Registered, please provide another number or we have dispatched the username and password to already registered number '+phone)
                } else{
                  setResetPassword(resp.data)
                }
              }).catch((err) => {});
            } else {
              setRegisterErr(msg[resp.data]);
            }
          })
          .catch((err) => {});
  }


  
  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timer);
          // Handle timer expiration here
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);

    return () => clearInterval(timer); // Cleanup the interval on component unmount

  }, [resent]);
  
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };
  
  return (
    <div className="otp-form">
      <div className="row">
        <div
          className="col-12 subTitel text-center"
          style={{ color: "#FCB503", fontSize: "1.35rem" }}
        >
          <TranslatesContent contentKey="otp-expires-in" /> { formatTime(time) }
        </div>
      </div>
      <p className="text-center" style={{ color: "green" }}>
        <TranslatesContent contentKey="we-have-sent" />
      </p>
      <p className="text-center" style={{ color: "red" }}>
        <TranslatesContent contentKey="please-note-this" />
      </p>  
      <div className="col-12 subTitel">
        <TranslatesContent contentKey="enter-otp" />
      </div>
      <form className="row">
      <OtpInput value={otp} onChange={setOtp} numInputs={6} renderSeparator={<span style={{color:'transparent',fontSize:'40px'}}>-</span>}
      renderInput={(props) => <input {...props} 
      style={{width:'12%',height:'40px',border:'1px solid #ffff',
      backgroundColor:'transparent',borderRadius:'5px',color:'#fff',textAlign:'center'}}/>}
      inputType="tel"
      placeholder="0" />
      </form>
      <div className="otp-err" style={{ color: "red" }}>
        {otpErr && otpErr}
      </div>
      <div className="otp-err" style={{ color: "green" }}>
        {resent && resent}
      </div>
      <div style={{ color: "red", fontSize: "12px" }} >
        {resetPassword && resetPassword}
      </div>
      <div className="col-12 mt-4">
        <div className="row">
          <div className="col-6 text-start">
          <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITEKEY}>
            <RegisterButtonResend reSendOtp={reSendOtp}/>
          </GoogleReCaptchaProvider>
          </div>
          <div className="col-6 text-end">
            <button className="joinTopbutton" onClick={() => verifyOtp()}>
              <TranslatesContent contentKey="verify" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpForm;
