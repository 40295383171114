import { IMAGE_BUCKET_URL } from "../../DataStore/ApiConstant";
// import WhatsappButton from "../Cashier/whatsappButton";
import ImportantNotice from "./ImportantNotice";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import BonusOffer from "../TransactionCommon/BonusOffer";
import { CONTACT_DETAILS } from "../../constants/index";

const CrediCardPayment = () => {
  return (
    <>
      <div
        class="tab-pane fade"
        id="v-pills-messages"
        role="tabpanel"
        aria-labelledby="v-pills-messages-tab"
      >
        <div class="row">
          <div class="col-12 col-12 col-lg-7 col-md-7 col-sm-7 col-xl-7 offset-xl-4 offset-lg-4 offset-md-4 offset-sm-4 offset-0">
            <div class="row">
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-12">
                    <div class="row text-center">
                      <div class="col-12 mb-2 mb-lg-3">
                        <label for="exampleFormControlInput1" class="cashierNewTitel">
                          For Credit card Payments contact us
                        </label>
                      </div>

                      {/* <div class="col-12">
                        <a href="https://wa.me/+94765992992" target="_blank">
                          <div class="newcashierButton">
                            <i
                              class="fa-brands fa-whatsapp"
                              style={{ fontSize: "25px" }}
                              s
                            ></i>{" "}
                            +94 770 000 000
                          </div>
                        </a>
                      </div> */}
                      <div className="col-lg-8 offset-xl-2 offset-lg-2 offset-md-2 offset-sm-2 offset-0">
                        <div className="d-flex justify-content-center">
                          <div class="zoom">
                            <a href={`https://wa.me/${CONTACT_DETAILS.deposit.whatsapp}`} target="_blank">
                              <img
                                src={IMAGE_BUCKET_URL + "master_card_icon_new.png"}
                                class="img-fluid"
                                alt=""
                              ></img>
                            </a>
                          </div>
                          <div class="zoom">
                            <a href={`https://wa.me/${CONTACT_DETAILS.deposit.whatsapp}`} target="_blank">
                              <img
                                src={IMAGE_BUCKET_URL + "visa_icon_new.png"}
                                class="img-fluid"
                                alt=""
                              ></img>
                            </a>
                          </div>
                          <div class="zoom">
                            <a href={`https://wa.me/${CONTACT_DETAILS.deposit.whatsapp}`} target="_blank">
                              <img
                                src={IMAGE_BUCKET_URL + "amex_icon_new.png"}
                                class="img-fluid"
                                alt=""
                              ></img>
                            </a>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <WhatsappButton /> */}
        </div>
      </div>
    </>
  );
};

export default CrediCardPayment;
