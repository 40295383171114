import { Fragment,useEffect } from "react";
// import { GUEST_GAMES_DATA } from "../../DataStore/ApiConstant";
import BaccaratResultBoard from "../GameBoards/Baccarat/BaccaratResultBoard";
import AndarBoard from "../GameBoards/andarbahar/AndarBoard";
import DragonTigerBoard from "../GameBoards/dragonTiger/DragonTigerBoard";
import Lucky7 from "../GameBoards/lucky7/Lucky7";
import Roulette from "../GameBoards/roulette/RouletteBoard";
import GuestGame from "../GameBoards/GuestGame";
import {useGetForU} from "../../services/gameBoards";
import {useTableConfig, useTableConfigGames} from "../../services/config";
import { connect } from "react-redux";
import { BaccaratResult } from "../../DataStore";
import SingleBaccaratResultBoard from "../GameBoards/Baccarat/SingleBacResultBoardTwo";

const GameTabContent = ({ games, login, openPopup, baccaratResult, getBaccaratResult }) => {
let configData = useTableConfig()
let {data, isLoading} = useTableConfigGames()
// alert(localStorage.getItem('login')== null)

let forU = useGetForU()
  return (
    <div className="tab-content mt-lg-5" id="myTabContent">
      {games &&
        Object.keys(games).map((gameky, ky) => {
          // console.log("eeeeeeeeeeeeeeee", gameky);
          // console.log("kkkkkkkkkkkk", ky);
          const gmeId = gameky.trim().replace(/ /g, "");
          return (
            <div key={ gameky } className={"tab-pane fade" + (ky === 0 ? " show active" : "")} id={ gmeId } role="tabpanel" aria-labelledby={ gameky.toUpperCase() + "-tab" } >

              {!login ? ( <GuestGame game={games[gameky].games}  games={games} gameNme={gameky}  login={login} openPopup={openPopup}  />  ) 
              : ( 
              
              <Fragment>
                <div class="row">

                {/* { gameky && (gameky === "for_you") && <><AndarBoard /> <DragonTigerBoard /> <Lucky7 /></> } */}
                {gameky && (gameky === "for_you") && forU.isLoading == false && forU?.data?.length > 0 ? forU?.data?.map(item => {
                    if(item["table name"].substring(0, 3) == "and"){
                      return <AndarBoard config={data} isLoad={isLoading}/> 
                    }
                    if(item["table name"].substring(0, 3) == "lu7"){
                      return <Lucky7 config={data} isLoad={isLoading}/>
                    }
                    if(item["table name"].substring(0, 3) == "drt"){
                      return <DragonTigerBoard config={data} isLoad={isLoading}/>
                    }
                    if(item["table name"].substring(0, 3) == "rou"){
                      return <Roulette config={data} isLoad={isLoading}/> 
                    }
                    if(item["table name"].substring(0, 3) == "bac"){
                      // return  <SingleBaccaratResultBoard index={item["table name"]} result={baccaratResult?.data?.data[item["table name"]]}  Tblekey={item["table name"]}/> 
                      return baccaratResult && baccaratResult.data && <SingleBaccaratResultBoard configGame={data} isLoad={isLoading}  config={configData} index={item["table name"]} result={baccaratResult?.data?.data[item["table name"]]}  Tblekey={item["table name"]}/>
                      // return <BaccaratResultBoard config={data} isLoad={isLoading}/>
                    }

                  }) : null
                  
                }
               { gameky && (gameky === "baccarat") && ( <BaccaratResultBoard config={data} isLoad={isLoading}/> )}

                { gameky && (gameky === "andar_bahar") && <AndarBoard config={data} isLoad={isLoading}/> }

                { gameky && (gameky === "dragon_tiger") && <DragonTigerBoard config={data} isLoad={isLoading}/> }

                { gameky && (gameky === "lucky_7") && <Lucky7 config={data} isLoad={isLoading}/> }

                { gameky && (gameky === "roulette") && <Roulette config={data} isLoad={isLoading}/> }
                

                {/* { gameky && (gameky === "roulette") &&  <GuestGame game={games[gameky].games} gameNme={gameky} login={login} /> } */}
                </div>

                </Fragment>
              )}
            </div>
          );
        })}
    </div>
  );
};

// export default GameTabContent;

const mapStateToProps = (state) => {
  return {
    baccaratResult: state.BaccaratResults,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBaccaratResult: () => dispatch(BaccaratResult("bac")),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GameTabContent);
