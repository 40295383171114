import TranslatesContent from "../../../DataStore/Languages/translatesContent";

import { useAtom } from "jotai";
import { bonusStore } from "../../../DataStore/atom";
const DepositBonus = ({ closePopup }) => {
  const [Bonus, setBonus] = useAtom(bonusStore);
  return (
    <div className="row">
      <div class="row">
        <div class="col-12 mainTitel">DEPOSIT BONUS</div>

        <div class="col-12">
          <ol class="spcial-ol">
            <li>
              Players must fulfill the required turnover requirement (points) in
              order to transfer or withdraw.
            </li>
            <li>
              The bonus points must be met within 30 days or the remaining bonus
              amount shall expire.
            </li>
            <li>
              Players can withdraw the bonus after fulfilling the turnover
              requirement.
            </li>
            <li>
              In case of early termination, the given bonus will be deducted
              from the wallet value.
            </li>
            <li>
              Players who have enrolled for bonus program can only withdraw
              their winnings after completing of the required bonus points only.
            </li>
            <li>
              If customer still needs to withdraw the winnings, then the given
              Bonus will be canceled.{" "}
            </li>
            <li>
              Any promotion cannot be used in conjunction with any other
              promotional offer.{" "}
            </li>
            <li>Only one account per player is allowed.</li>
            <li>
              Players opening multiple or fraudulent accounts will be
              disqualified from the promotion. Remaining amount could be
              forfeited and the account shall be frozen.
            </li>
            <li>
              ballysbreeze.com reserves the right to modify, alter, discontinue,
              cancel, refuse or void this promotion at its sole discretion.
            </li>
            <li>
              Participating players must accept and comply with the terms of
              this promotion.
            </li>
          </ol>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-4 col-md-12 col-sm-4 offset-lg-4 offset-md-3 offset-sm-4 offset-2 d-flex">
          <button
            type="button"
            class="btn cancelButton"
            data-bs-dismiss="modal"
            onClick={(e) => {
              e.preventDefault();
              setBonus(false);
              closePopup();
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn newcashierButton"
            onClick={() => {
              setBonus(!Bonus);
              closePopup();
            }}
          >
            {Bonus ? 'Activated':'Activate'}
            
          </button>
        </div>
      </div>
    </div>
  );
};

export default DepositBonus;
